import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "~/utils";

const pageHeaderVariants = cva("", {
  variants: {
    variant: {
      header: "py-8 md:py-16 text-white bg-neutral-900",
      title: "py-6 md:py-12 text-neutral-900 bg-white",
    },
  },
  defaultVariants: {
    variant: "header",
  },
});

export interface PageHeaderProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof pageHeaderVariants> {
  title?: string;
  subtitle?: string;
}

function PageHeader({
  title,
  subtitle,
  className,
  variant,
  ...props
}: PageHeaderProps) {
  return (
    <div
      style={{
        viewTransitionName: variant === "header" ? `page-header` : undefined,
      }}
      className={cn(pageHeaderVariants({ variant }), className)}
      {...props}
    >
      <h1 className="container text-2xl text-center uppercase lg:text-4xl font-heading">
        {title}
      </h1>
      {subtitle && (
        <p className="container pt-12 text-xl font-medium text-center">
          {subtitle}
        </p>
      )}
    </div>
  );
}

export { PageHeader, pageHeaderVariants };
